<template>
  <div class="content">
    <div class="wrapper">
      <div class="banner">
        <img src="../assets/images/game_combination_banner.png" />
      </div>
      <div class="step-group">
        <div class="step-1" :class="step === 1 && 'active'"></div>
        <div class="step-2" :class="step === 2 && 'active'"></div>
        <div class="step-3" :class="step === 3 && 'active'"></div>
      </div>
      <div class="css-dj0le7 e1w3ytqh36">
        <div class="des">
          <img
            draggable="false"
            src="../assets/images/26a7179a.png"
          />272013人已成功带走心愿礼包
        </div>
        <div class="css-1jn3bzr e1w3ytqh23">
          <section>
            <span
              >135****9876
              支付了¥88获得了总价值¥1376的游戏：【海贼王：世界探索者】【石油骚动】【狐姬】【多啦梦：牧场物语】【彩虹六号标准版】【战地1】【安娜贝尔的诅咒】【逆光】【怪兽远征】【故乡的记忆味缘篇】</span
            >
            <span
              >188****6465
              支付了¥88获得了总价值¥3109的游戏：【刺客信条：英灵殿】【饥荒联机版】【三国志14】【泰拉瑞亚】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【怪物猎人：世界】【侠盗猎车手5（GTA5）】【最后的绿洲】</span
            >
            <span
              >156****0733
              支付了¥88获得了总价值¥1388的游戏：【毁灭战士：永恒】【僵尸部队4：亡灵大战】【方舟：生存进化】【人类一败涂地】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【刺客信条:起源】【僵尸世界大战】【最后的绿洲】</span
            >
            <span
              >134****4536
              支付了¥88获得了总价值¥1339的游戏：【毁灭战士：永恒】【僵尸部队4：亡灵大战】【光环：士官长合集】【人类一败涂地】【港诡实录】【龙珠Z：卡卡罗特】【刺客信条:起源】【真三国无双7：猛将传】【莎木3】</span
            >
            <span
              >150****5552
              支付了¥88获得了总价值¥956的游戏：【无主之地3标准版】【欺诈之地（steam）】【全面战争：三国】【太吾绘卷】【星露谷物语】【冰汽时代】【破坏领主】【我爱你山德斯上校】【小偷模拟器】</span
            >
            <span
              >150****6547
              支付了¥88获得了总价值¥2675的游戏：【海贼王：世界探索者】【彩虹六号标准版】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【刺客信条：英灵殿】【最后的绿洲】【怪物猎人：世界】【刺客信条:起源】</span
            >
            <span
              >158****5437
              支付了¥88获得了总价值¥644的游戏：【无主之地3标准版】【太吾绘卷】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【冰汽时代】【无主之地3标准版】【最后的绿洲】【毁灭战士：永恒】【星露谷物语】</span
            >
            <span
              >177****5437
              支付了¥88获得了总价值¥644的游戏：【无主之地3标准版】【太吾绘卷】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【冰汽时代】【无主之地3标准版】【最后的绿洲】【毁灭战士：永恒】【星露谷物语】</span
            >
            <span
              >135****9876
              支付了¥88获得了总价值¥1507的游戏：【海贼王：世界探索者】【石油骚动】【狐姬】【多啦梦：牧场物语】【彩虹六号标准版】【战地1】【安娜贝尔的诅咒】【逆光】【怪兽远征】【故乡的记忆味缘篇】</span
            >
            <span
              >188****6465
              支付了¥88获得了总价值¥3109的游戏：【刺客信条：英灵殿】【饥荒联机版】【三国志14】【泰拉瑞亚】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【怪物猎人：世界】【侠盗猎车手5（GTA5）】【最后的绿洲】</span
            >
            <span
              >156****0733
              支付了¥88获得了总价值¥1388的游戏：【毁灭战士：永恒】【僵尸部队4：亡灵大战】【方舟：生存进化】【人类一败涂地】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【刺客信条:起源】【僵尸世界大战】【最后的绿洲】</span
            >
            <span
              >134****4536
              支付了¥88获得了总价值¥1339的游戏：【毁灭战士：永恒】【僵尸部队4：亡灵大战】【光环：士官长合集】【人类一败涂地】【港诡实录】【龙珠Z：卡卡罗特】【刺客信条:起源】【真三国无双7：猛将传】【莎木3】</span
            >
            <span
              >150****5552
              支付了¥88获得了总价值¥956的游戏：【无主之地3标准版】【欺诈之地（steam）】【全面战争：三国】【太吾绘卷】【星露谷物语】【冰汽时代】【破坏领主】【我爱你山德斯上校】【小偷模拟器】</span
            >
            <span
              >150****6547
              支付了¥88获得了总价值¥2675的游戏：【海贼王：世界探索者】【彩虹六号标准版】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【漫威复仇者联盟】【刺客信条：英灵殿】【最后的绿洲】【怪物猎人：世界】【刺客信条:起源】</span
            >
            <span
              >158****5437
              支付了¥88获得了总价值¥644的游戏：【无主之地3标准版】【太吾绘卷】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【冰汽时代】【无主之地3标准版】【最后的绿洲】【毁灭战士：永恒】【星露谷物语】</span
            >
            <span
              >177****5437
              支付了¥88获得了总价值¥644的游戏：【无主之地3标准版】【太吾绘卷】【安娜贝尔的诅咒】【糖豆人:
              终极淘汰赛】【冰汽时代】【无主之地3标准版】【最后的绿洲】【毁灭战士：永恒】【星露谷物语】</span
            >
          </section>
        </div>
      </div>
      <div class="css-q3wzem e1w3ytqh35">
        <div class="css-gg4vpm e1w3ytqh34">
          <div class="css-3iv7yi e1w3ytqh33">
            <div class="css-1bntj9o e1w3ytqh30" style="align-items: flex-end">
              <div class="css-1fgrwst e1w3ytqh31">
                <img src="../assets/images/97b73624.png" draggable="false" />
              </div>
              <div
                class="e1w3ytqh2"
                :class="bagList.length < 3 ? 'css-10flynr' : 'css-ehq7cr'"
                v-show="bagList.length > 0"
                @click="reDraw"
              >
                重新抽取 ({{ bagList.length }}/3)
              </div>
            </div>
            <div class="css-dbvt3o e1w3ytqh28">
              <div
                class="css-1lzlv4l e1w3ytqh27"
                v-for="(item, i) in preferences[showPreferenceIndex]"
                :key="item.id"
              >
                <div
                  class="cardBox no-active"
                  style="transition-delay: 0s"
                  :style="i === 4 && 'cursor: pointer;'"
                  @click="i === 4 && firstDraw()"
                >
                  <div
                    class="front"
                    :style="
                      'background-image: url(' +
                      item.frontImg +
                      '); background-size: 100% 100%;'
                    "
                  ></div>
                  <div class="back-bg">
                    <div
                      class="back"
                      :style="
                        'background-image: url(' +
                        `${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/preference/${item.preference}` +
                        ');background-size: 100% 100%;background-position: center center;'
                      "
                    >
                      <div class="bottom">{{ item.gamename_zh }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="css-1v994a0 e1w3ytqh32">
            <div class="css-1fgrwst e1w3ytqh31">
              <img src="../assets/images/eb52815e.png" draggable="false" />
            </div>
            <div class="css-fdq13z e1w3ytqh26" v-if="!selectGame.id">
              <div class="mark">
                <span
                  role="img"
                  aria-label="delete"
                  class="anticon anticon-delete"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="delete"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                    ></path>
                  </svg>
                </span>
              </div>
              <img
                src="../assets/images/1d75f6a1.png"
                class="css-bsfus6 e1w3ytqh19"
                @click="choiceGame"
              />
              <img
                draggable="false"
                src="../assets/images/08c8a3d9.png"
                class="pointer"
              />
              <div class="plus">
                <span role="img" aria-label="plus" class="anticon anticon-plus">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="plus"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"
                    ></path>
                    <path
                      d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"
                    ></path>
                  </svg>
                </span>
              </div>
              <!-- <div class="text">前往挑选</div> -->
            </div>
            <div class="css-fdq13z e1w3ytqh26" v-if="selectGame.id">
              <img
                src="../assets/images/1d75f6a1.png"
                class="css-bsfus6 e1w3ytqh19"
                @click="choiceGame"
              />
              <img
                style="
                  width: 282px;
                  height: 160px;
                  position: absolute;
                  top: 8px;
                  left: 10px;
                  z-index: 10;
                "
                :src="`${BASE_IMAGE_URL}/${selectGame.platform}/${selectGame.gameid}/list/${selectGame.list}`"
                draggable="false"
              />
              <div
                class="choose-game-remove"
                style="
                  width: 282px;
                  height: 160px;
                  position: absolute;
                  top: 8px;
                  left: 10px;
                  z-index: 11;
                "
                @click="selectGame = {}"
              >
                <div style="justify-content: center; align-items: center">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    class=""
                    data-icon="delete"
                    width="34px"
                    height="34px"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="css-1fgrwst e1w3ytqh31"
              style="margin-bottom: 0px; margin-top: 20px"
            >
              <img src="../assets/images/d335d3c3.png" draggable="false" />
            </div>
            <div class="css-1j3m4gj e1w3ytqh25">
              <div class="mainText">
                套餐价：<span style="color: rgb(255, 71, 71)">￥</span
                ><span class="primary">88</span>
              </div>
              <div class="num">
                套餐总价值：<span class="primary"
                  >¥{{ calculationMoney || "???" }}</span
                >
              </div>
              <div
                id="pay-btn"
                class="btn"
                :class="calculationMoney || 'disabled'"
                @click="showDialog"
              ></div>
            </div>
          </div>
        </div>

        <div class="css-g6nyvl e1w3ytqh6">
          <img
            src="../assets/images/b0612b85.png"
            alt=""
            class="title"
            draggable="false"
          />
          <div id="chance-group" class="css-u9zg5w e1w3ytqh5">
            <!-- 显示游戏礼包 -->
            <div
              class="css-18whfww e1w3ytqh4"
              v-for="(bag, i) in bagList"
              :key="i"
            >
              <img
                src="../assets/images/97bc83d4.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 0"
              />
              <img
                src="../assets/images/7d8642ed.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 1"
              />
              <img
                src="../assets/images/8946539a.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 2"
              />
              <div class="group-gift-item" v-show="true">
                <div class="gift-item">
                  <div
                    class="css-1hux5ry e1w3ytqh3"
                    v-for="item in bag"
                    :key="item.id"
                  >
                    <img
                      :src="`${BASE_IMAGE_URL}/${item.platform}/${item.gameid}/preference/${item.preference}`"
                      class="gift-item-img"
                      draggable="false"
                    />
                    <div class="text">{{ item.gamename_zh }}</div>
                  </div>
                </div>
                <img
                  src="../assets/images/0cc83ee7.png"
                  alt=""
                  class="gift-item-btn"
                  draggable="false"
                  @click="useBag(i)"
                />
              </div>
            </div>
            <!-- 显示宝箱图片 -->
            <div
              class="css-18whfww e1w3ytqh4"
              v-for="(item, i) in 3 - bagList.length"
              :key="i + bagList.length"
            >
              <img
                src="../assets/images/97bc83d4.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 0"
              />
              <img
                src="../assets/images/7d8642ed.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 1"
              />
              <img
                src="../assets/images/8946539a.png"
                alt=""
                class="gift-title"
                draggable="false"
                v-if="i === 2"
              />
              <img
                src="../assets/images/9a94f367.png"
                class="gift-icon"
                draggable="false"
              />
            </div>
          </div>
        </div>

        <div class="css-1vkf9ih e1w3ytqh24">
          <div class="css-1q9hr6j e1w3ytqh22"></div>
          <div class="css-r9srwx e1w3ytqh18">
            <div class="css-5d978z e1w3ytqh17">
              1、 该活动将不限制参与次数 每次购买完成后将刷新抽取次数
            </div>
            <div class="css-5d978z e1w3ytqh17">
              2、 每次用户均有3次抽取随机游戏组合的机会
            </div>
            <div class="css-5d978z e1w3ytqh17">
              3、 用户在活动页面内选择一种随机游戏组合+1款自选游戏
            </div>
            <div class="css-5d978z e1w3ytqh17">
              4、 不论任意游戏最终打包价格88元
            </div>
            <div class="css-5d978z e1w3ytqh17">
              5、 由于活动商品为特殊商品因此无法退款，如有特殊情况请联系客服
            </div>
            <div class="css-5d978z e1w3ytqh17">
              6、 本次活动商品内的游戏均为“共享”游戏
            </div>
            <div class="css-5d978z e1w3ytqh17">7、 若有疑问请联系在线客服</div>
            <div class="css-5d978z e1w3ytqh17">
              8、 本次活动所有解释权归本平台官方所有
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择游戏 -->
    <ChoiceGame
      v-show="showChoiceGame"
      :hadeChoiceGameFn="() => (this.showChoiceGame = false)"
      :selectedGameFn="selectedGame"
    ></ChoiceGame>

    <!-- 支付二维码 -->
    <PayModal
      v-show="dialogVisible"
      :games="games"
      :hidePayModal="() => (this.dialogVisible = false)"
      :isShowForm="isShowForm"
    ></PayModal>
  </div>
</template>

<script>
import ChoiceGame from "./ChoiceGame.vue";
import PayModal from "../components/PayModal2.vue";
import { homeApi } from "../api";
import { BASE_IMAGE_URL } from "../common/config.json";
import { getUUID, getSign,getUser } from "../common/utils";
import frontImg_1 from "../assets/images/54a3ca6f.png";
import frontImg_2 from "../assets/images/6a3682b6.png";
import frontImg_3 from "../assets/images/beb92386.png";
import frontImg_4 from "../assets/images/cf19c00c.png";
import frontImg_5 from "../assets/images/412bfd2c.png";
import frontImg_6 from "../assets/images/564ead5f.png";
import frontImg_7 from "../assets/images/be008813.png";
import frontImg_8 from "../assets/images/beb92386.png";
import frontImg_9 from "../assets/images/10b2a35a.png";

export default {
  name: "GameCombination",
  components: {
    ChoiceGame,
    PayModal,
  },
  data() {
    return {
      BASE_IMAGE_URL,
      step: 1,
      bagList: [],
      preferences: [],
      showPreferenceIndex: 0,
      addPreferenceIndex: 0,
      frontImgs: [
        frontImg_1,
        frontImg_2,
        frontImg_3,
        frontImg_4,
        frontImg_5,
        frontImg_6,
        frontImg_7,
        frontImg_8,
        frontImg_9,
      ],
      showChoiceGame: false,
      selectGame: {},
      showRemoveGame: false,
      dialogVisible: false,
      firstDrawState: false,
      flag: false,
      isShowForm: true,
    };
  },
  mounted() {
    this.loadData();

    document.querySelector(".foot").style.marginTop = 0;
  },
  destroyed() {
    document.querySelector(".foot").style.marginTop = "100px";
  },
  computed: {
    calculationMoney() {
      if (
        this.preferences.length > 0 &&
        this.bagList.length > 0 &&
        this.selectGame.id
      ) {
        const preference = this.preferences[this.showPreferenceIndex];
        const money = preference.reduce((pre, item) => (pre += item.money), 0);
        return money + this.selectGame.money;
      }
      return 0;
    },
    games() {
      if (
        this.preferences.length > 0 &&
        this.bagList.length > 0 &&
        this.selectGame.id
      ) {
        return [...this.preferences[this.showPreferenceIndex], this.selectGame];
      }
      return [];
    },
  },
  methods: {
    loadData() {
      homeApi.reqNice(getUUID(), 0, getSign()).then((res) => {
        this.preferences.push(
          res.preference.map((item, i) => {
            item.frontImg = this.frontImgs[i];
            return item;
          })
        );
      });
      homeApi.reqNice(getUUID(), 0, getSign()).then((res) => {
        this.preferences.push(
          res.preference.map((item, i) => {
            item.frontImg = this.frontImgs[i];
            return item;
          })
        );
      });
      homeApi.reqNice(getUUID(), 0, getSign()).then((res) => {
        this.preferences.push(
          res.preference.map((item, i) => {
            item.frontImg = this.frontImgs[i];
            return item;
          })
        );
      });
    },

    firstDraw() {
      if (!this.firstDrawState) {
        this.firstDrawState = true;
        this.showBag("add");
      }
    },
    showDialog() {
      if (this.calculationMoney) {
        this.isShowForm = getUser()?false:true;
        this.dialogVisible = true;
      }
    },
    showBag(type) {
      this.step = 2;

      if (this.flag) {
        return;
      } else {
        this.flag = true;
      }

      // 递归添加翻转动画class
      const domList = document.querySelectorAll(".cardBox");
      mode(domList);

      let index = 0;
      let _this = this;
      function mode(domList) {
        const timer = setTimeout(() => {
          domList[index] && domList[index].classList.add("rotateY");
          clearTimeout(timer);
          if (index < domList.length) {
            index++;
            mode(domList);
          } else {
            if (type === "add") {
              // 动画结束后添加到宝箱
              _this.bagList.length < 3 &&
                _this.bagList.push(
                  _this.preferences[_this.showPreferenceIndex]
                );
            }
            _this.flag = false;
          }
        }, 500);
      }
    },
    reDraw() {
      if (this.bagList.length >= 3 || this.flag) return;
      const domList = document.querySelectorAll(".cardBox");
      domList.forEach((item) => item.classList.remove("rotateY"));
      this.showPreferenceIndex++;
      this.$nextTick(() => this.showBag("add"));
    },
    useBag(i) {
      if (this.flag) return;
      const domList = document.querySelectorAll(".cardBox");
      domList.forEach((item) => item.classList.remove("rotateY"));
      this.showPreferenceIndex = i;
      this.$nextTick(() => this.showBag("use"));
    },
    choiceGame() {
      if (this.bagList.length === 0) {
        this.$message({
          type: "warning",
          message: "请先完成第一步拆礼包",
        });
      } else {
        this.showChoiceGame = true;
      }
    },
    selectedGame(game) {
      // console.log(game)
      this.step = 3;
      this.selectGame = game;
    },
  },
};
</script>

<style scoped>
img {
  width: auto;
  height: auto;
}
.content {
  margin-top: 60px;
  max-width: 1461px;
  margin: 0 auto;
}
.wrapper {
  position: relative;
  width: 100%;
  background-image: url("../assets/images/game_combination_background.png");
  background-size: 100% 100%;
}
.banner {
  padding: 0;
}
.banner > img {
  width: 100%;
  height: auto;
}
.step-group {
  position: absolute;
  top: 17%;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  padding: 0 20%;
  background-image: url("../assets/images/bedae46b.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-y: -38.4px;
  box-sizing: border-box;
}
.step-group > div {
  width: 120px;
  height: 143px;
}
.step-1 {
  background-image: url("../assets/images/game_combination_step_1.png");
  background-position: 0 0;
}
.step-2 {
  background-image: url("../assets/images/game_combination_step_2.png");
  background-position: 0 0;
}
.step-3 {
  background-image: url("../assets/images/game_combination_step_3.png");
  background-position: 0 0;
}
.active {
  background-position: -1080px 0;
}
.css-dj0le7 {
  width: 983px;
  height: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 16px;
  transform: scale(0.9);
  padding: 0px 20px;
  background: url(../assets/images/bf37d7f8.png) 0% 0% / 100% no-repeat;
  margin: 72px auto 0px;
}
.css-dj0le7 .des {
  display: flex;
  font-weight: 500;
  color: rgb(255, 255, 255);
}
.css-dj0le7 .des > img {
  margin-right: 5px;
}
.css-1jn3bzr {
  height: 40px;
  line-height: 40px;
  margin-left: 22px;
  flex: 1 1 0%;
  overflow: hidden;
  position: relative;
}
.css-1jn3bzr > section {
  position: absolute;
  left: 0px;
  top: 0px;
  white-space: nowrap;
  font-size: 16px;
  color: rgb(255, 232, 129);
  animation: rotation 300s linear infinite;
  -moz-animation: rotation 300s linear infinite;
  -webkit-animation: rotation 300s linear infinite;
  -o-animation: rotation 300s linear infinite;
  font-weight: 400;
  opacity: 0.8;
}
.css-1jn3bzr > section span {
  margin-right: 20px;
}
@keyframes rotation {
  0% {
    transform: translateX(200px);
    -webkit-transform: translateX(200px);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
.css-q3wzem {
  flex: 1 1 0%;
  padding: 12px 5px 0px 15px;
  transform: scale(0.9);
  margin-top: -25px;
  max-width: 1016px;
  margin-left: auto;
  margin-right: auto;
}
.css-gg4vpm {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-3iv7yi {
  width: 670px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
}
.css-1bntj9o {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.css-dbvt3o {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

.css-1lzlv4l {
  width: 212px;
  height: 142px;
  margin-bottom: 18px;
  margin-right: 9px;
  position: relative;
  perspective: 500px;
}
/* .css-1lzlv4l .cardBox.no-active {
    transition: transform 0s ease 0s !important;
} */
.css-1lzlv4l .cardBox {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 1s ease 0s;
  transform-style: preserve-3d;
}
.css-1lzlv4l .rotateY {
  transform: rotateY(180deg);
}
.css-1lzlv4l .rotateY-reset {
  transform: rotateY(-180deg);
}
.css-1lzlv4l .cardBox .back-bg {
  transform: rotateY(180deg);
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: url("../assets/images/bbae2e35.png") 0% 0% / contain no-repeat;
}
.css-1lzlv4l .cardBox .back-bg,
.css-1lzlv4l .cardBox .front {
  width: 212px;
  height: 142px;
  position: absolute;
  backface-visibility: hidden;
  top: 0px;
  left: 0px;
}
.css-1lzlv4l .cardBox .back {
  width: 188px;
  height: 133px;
}
.css-1lzlv4l .cardBox .back .bottom {
  width: 188px;
  height: 24px;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0px);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  color: rgb(217, 204, 185);
}
.css-1v994a0 {
  margin-right: 6px;
}
.css-1fgrwst {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 500;
  color: rgb(254, 246, 210);
  margin-bottom: 8px;
}
.css-10flynr {
  width: 165px;
  height: 40px;
  user-select: none;
  cursor: pointer;
  color: rgb(61, 39, 39);
  background: url("../assets/images/865825d2.png") center center / contain
    no-repeat;
  margin-right: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  opacity: 0.9;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-ehq7cr {
  width: 165px;
  height: 40px;
  user-select: none;
  background: url("../assets/images/6fd87957.png") center center / contain
    no-repeat;
  color: rgb(62, 62, 62);
  cursor: not-allowed;
  margin-right: 20px;
  margin-bottom: 20px;
  font-weight: 500;
  opacity: 0.9;
  font-size: 16px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.css-fdq13z {
  position: relative;
  width: 303px;
  height: 177px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  margin: 0px auto 5px;
}
.choose-game-remove > div {
  display: none;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.choose-game-remove:hover > div {
  display: flex;
}
.css-bsfus6 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.css-1j3m4gj {
  width: 268px;
  height: 214px;
  background: rgba(0, 0, 0, 0.6);
}
.css-1j3m4gj .mainText {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  margin-left: 34px;
  margin-bottom: 4px;
}
.css-1j3m4gj .num {
  font-size: 18px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  margin-left: 34px;
}
.css-1j3m4gj .mainText .primary {
  color: rgb(255, 71, 71);
  font-size: 37px;
  font-weight: 500;
  line-height: 37px;
}
.css-1j3m4gj .btn.disabled {
  cursor: not-allowed;
  background: url(../assets/images/ed180378.png) center center / 100% no-repeat;
  color: rgb(238, 238, 238);
}
.css-1j3m4gj .btn {
  margin-top: 4px;
  width: 302px;
  height: 88px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: rgb(255, 237, 173);
  background: url(../assets/images/9d76ec5a.png) center center / 100% no-repeat;
  cursor: pointer;
  margin-left: 24px;
}
.css-g6nyvl {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  transform: scale(0.9);
}

.css-u9zg5w {
  width: 981px;
  height: 307px;
  margin-top: 38px;
  display: flex;
  background: url(../assets/images/5d058908.png) 0% 0% / contain no-repeat;
}

.css-18whfww {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.css-18whfww .gift-title {
  margin-top: 32px;
}

.css-18whfww .group-gift-item {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 5px;
}

.css-18whfww .gift-item {
  width: 220px;
  height: 160px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: url("../assets/images/0b45cb73.png") 0% 0% / 100% 100% no-repeat;
  padding: 10px;
}

.css-1hux5ry {
  position: relative;
  width: 64px;
  height: 46px;
  margin-right: 2px;
  cursor: pointer;
}

.css-1hux5ry .gift-item-img {
  width: 100%;
  height: 100%;
  display: block;
}

.css-1hux5ry .text {
  display: none;
}

.css-1hux5ry:hover .text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
}

.css-18whfww .gift-item-btn {
  cursor: pointer;
  margin-top: 5px;
}

.css-1vkf9ih {
  margin-top: 76px;
  padding: 0px 62px;
}

.css-1q9hr6j {
  width: 934px;
  height: 54px;
  background: url(../assets/images/27db0cb7.png) 0% 0% / contain no-repeat;
}

.css-r9srwx {
  padding: 42px 18px;
}

.css-5d978z {
  font-size: 16px;
  color: rgb(202, 188, 123);
  line-height: 24px;
  margin-bottom: 20px;
}
</style>
